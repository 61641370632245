.packs-topbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.05);
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    max-width: 1350px;
    margin: 0 auto;
  }
  &__left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-right: 20px;
    .btn {
      white-space: nowrap;
    }
    .btn-back {
      svg {
        transform: scaleX(-1);
        margin-right: 10px;
      }
    }
    .btn-problem {
      background-color: #ffb822;
      border-color: #ffb822;
      span {
        color: #fff;
      }
      svg {
        [fill] {
          fill: #fff;
        }
      }
      &:hover {
        background-color: rgba(255, 184, 34, 0.7);
      }
    }
    .pack-number {
      padding: 0 20px;
      min-width: 180px;
      display: flex;
      align-items: center;
      p {
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        margin: 2px 0;
        color: #595d6e;
      }
      .link {
        transition: 0.2s;
        text-decoration: underline;
        cursor: pointer;
        &:hover {
          color: #5d78ff;
        }
      }
      svg {
        max-width: 20px;
        margin-right: 5px;
      }
    }
    .packing-box-group {
      border-left: 1px solid #ebedf2;
      padding: 0 20px;
      min-height: 40px;
      display: flex;
      align-items: center;
      span {
        font-size: 30px;
        line-height: 38px;
        color: #fd397a;
      }
    }
    .speditor-group {
      border-left: 1px solid #ebedf2;
      padding-left: 20px;
      flex: 1;
      &__title {
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        color: #595d6e;
      }
      &__info {
        .delivery-methods {
          font-size: 12px;
          line-height: 16px;
          font-weight: 300;
          display: inline-block;
        }
        span {
          font-size: 15px;
          line-height: 20px;
          font-weight: 500;
          color: #595d6e;
        }
        img {
          max-height: 20px;
        }
      }
    }
    .pack-group {
      display: flex;
      align-items: center;
      padding-left: 40px;
      margin-left: 40px;
      border-left: 1px solid #ebedf2;
      &__title {
        font-size: 13px;
        line-height: 20px;
        font-weight: 300;
        white-space: nowrap;
        color: #595d6e;
      }
      &__value {
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #595d6e;
      }
      &__image {
        flex-basis: 30px;
        max-width: 30px;
      }
      &__text {
        flex-basis: calc(100% - 30px);
        max-width: calc(100% - 30px);
      }
    }
  }
  &__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 20px;
    .image-upload {
      display: flex;
      align-items: center;
      .image {
        width: 48px;
        height: 48px;
        border: 1px dashed #e2e5ec;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        background-size: cover;
        background-position: center;
        &.preview {
          position: relative;
          cursor: pointer;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(
              $color: #5d78ff,
              $alpha: 0.8
            );
            opacity: 0;
            transition: 0.2s;
            z-index: 1;
          }
          &::after {
            content: url("../../images/icons/zoom.svg");
            position: absolute;
            z-index: 2;
            top: 50%;
            left: 50%;
            opacity: 0;
            transition: 0.2s;
            transform: translate(-50%, -50%);
          }
          &:hover {
            &::before,
            &::after {
              opacity: 1;
            }
          }
        }
      }
      .btn-picture {
        border-radius: 4px;
        padding: 5px 13px;

        span {
          color: #fff;
        }
      }
    }
  }
}
