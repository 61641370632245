.table-missings {
  .product-group-container {
    .products-list {
      font-weight: 100;
      color: #595d6e;
      & > li {
        .table-head {
          tr {
            th:not(:first-child) {
              padding-left: 10px;
            }
          }
        }
      }
      .product {
        cursor: pointer;
        &.active {
          background-color: rgb(249, 249, 249);
        }
        &:hover {
          background-color: rgba(249, 249, 249, 0.7);
        }
        border-bottom: 1px solid #ebedf2;
        tr {
          border-bottom: none;
          &:hover {
            background-color: initial;
          }
        }
        .table__body {
          margin: 0 20px;
          .collapse {
            padding: 0;
            text-align: center;
            .btn-collapse {
              padding: 10px;
            }
          }
          tr {
            td {
              padding: 12px 10px;
              padding-right: 0;
            }
            td:last-child {
              padding-right: 10px;
            }
          }
        }
      }
    }
  }
  .product-group-container {
    .link {
      color: #5d78ff;
      transition: 0.2s;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
        text-decoration: underline;
      }
    }
    .products-list {
      .inner-table {
        padding: 20px 30px 30px;
        border-radius: 4px;
        cursor: default;
        background-color: #fff;
        .table {
          background-color: white;
          box-shadow: 0px 0px 10px rgba(113, 106, 202, 0.1);
          tr {
            border-bottom: 1px solid $separator !important;
          }

          &__head--inner {
            tr {
              th {
                padding: 10px;
                padding-left: 10px;
                padding-bottom: 10px;
                font-weight: 500;
              }
              th:first-child {
                padding-left: 20px;
              }
            }
          }
          &__body--inner {
            tr {
              transition: 0.2s;
              &:hover {
                background-color: rgba(249, 249, 249, 0.7);
              }
            }
            td {
              padding: 10px 5px;
            }
            td:first-child {
              padding-left: 20px;
            }
          }
        }
      }
    }
  }

  .preview {
    .btn-preview {
      width: 50px;
      height: 50px;
      border-radius: 4px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      overflow: hidden;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($color: #5d78ff, $alpha: 0.8);
        opacity: 0;
        transition: 0.2s;
        z-index: 1;
      }
      &::after {
        content: url("../../images/icons/zoom.svg");
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        opacity: 0;
        transition: 0.2s;
        transform: translate(-50%, -50%);
      }
      &:hover {
        &::before,
        &::after {
          opacity: 1;
        }
      }
    }
  }
}
