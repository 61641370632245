.alert {
  border-radius: 4px;
  padding: 15px 30px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: #fff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
  &.info {
    background-color: #44b2f1;
  }
  &.warning{
    background-color: #ffb822;
  }
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.1);
  }
  &__content {
    padding-left: 20px;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
  }
}
