@import "~react-redux-toastr/lib/css/react-redux-toastr.min.css";
@import "~@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
@import "~react-dropzone-uploader/dist/styles.css";
@import "~react-markdown-editor-lite/lib/index.css";
@import "reset.scss";
@import "colors.scss";
@import "mixins.scss";
@import "typography.scss";
@import "expano-components.scss";
@import "partials/dashboard.scss";
@import "table.scss";
@import "partials/layout.scss";
@import "partials/page-loader.scss";
@import "partials/tree.scss";
@import "partials/warehouse-panel.scss";
@import "partials/product_statuses.scss";
@import "partials/product.scss";
@import "partials/delivery.scss";
@import "partials/tooltip.scss";
@import "partials/release.scss";
@import "partials/packs-topbar.scss";
@import "partials/packs-list.scss";
@import "partials/missings.scss";
@import "partials/delivery-label-modal.scss";
@import "partials/calendar.scss";
@import "partials/qr-code-modal.scss";
@import "partials/main_table.scss";
@import "partials/alert.scss";
@import "partials/packer-modal.scss";
@import "partials/aisle-preview.scss";
@import "partials/expano-flag.scss";
@import "partials/packs-count.scss";
@import "partials/main-list.scss";
@import "partials/modal-table.scss";
@import "partials/status-panel.scss";
@import "partials/packer-statistics-bar.scss";
@import "partials/button-group.scss";
@import "partials/confirm-panel.scss";
@import "partials/speditors-prices.scss";
@import "partials/package-info-panel.scss";
@import "partials/delivery_methods.scss";
@import "partials/inventarization.scss";
@import "partials/progress-bar.scss";
@import "partials/chart-panel.scss";

@import "views/gdn-packs.scss";
@import "views/dashboard.scss";
@import "views/grn.scss";

.rc-md-navigation {
  background: #fff;
}

.rc-md-editor .editor-container .sec-md .input {
  padding-top: 20px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.rc-md-editor .tool-bar {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
}

$spaceamounts: (
  0,
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  75,
  100
); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .m-#{$space} {
      margin: #{$space}px !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }

    .p-#{$space} {
      padding: #{$space}px !important;
    }
  }
}

.no_sidebar {
  .sidebar {
    display: none;
  }
}

.no_topbar {
  .main-navbar {
    display: none;
  }
}

.no_topbar.no_sidebar {
  .main-container {
    margin-left: 0;
    width: 100%;
  }
}

.image-uploader {
  padding: 0;

  .image-uploader__box {
    height: 300px;
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
  }
}

.dzu-dropzone {
  &::-webkit-scrollbar {
    display: none;
  }
}

.btn-success {
  font-size: 13px;
  line-height: 20px;
  padding: 10px 15px;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  margin-left: 5px;
  color: #fff;
  background-color: #0abb87;
  transition: 0.2s;

  svg {
    margin-left: 10px;
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    transform: scale(0.95);
  }
}

.infinite-scroll-component {
  overflow: visible !important;
}

[class$="indicatorContainer"] {
  padding: 3px 5px !important;
}

.filters-select {
  [class$="indicatorContainer"] {
    padding: 0 !important;

    svg {
      max-width: 13px;
      max-height: 13px;
    }
  }
}

.timestamp {
  svg {
    max-width: 18px;
    margin-right: 7px;
  }

  cursor: pointer;
  font-size: 13px;
  line-height: 20px;
  color: #595d6e;
}

.calendar-group {
  max-width: 270px;

  & > div {
    width: 100%;
  }

  .react-date-picker,
  .react-datetime-picker,
  .react-datetimerange-picker {
    &__wrapper {
      width: 100%;
      padding: 0px;
      border-radius: 4px;
      border: none;
      border: 1px solid #e2e5ec;
      width: 100%;
      outline: none;
      transition: 0.2s;
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      background: #f3f6f9;
      border-left: 1px solid #e2e5ec;
    }

    &__inputGroup {
      padding: 4px 8px;
      white-space: nowrap;
      input,
      span {
        font-size: 13px;
        line-height: 20px;
      }
    }
  }
}

.dashboard__list-item {
  .highlighted {
    color: #646c9a;
    font-weight: 500;
  }
}

.sidebar.collapsed{
  z-index: 11;
}