.chart-panel {
  &__body {
    padding: 20px 30px;
  }
  &__form {
    width: calc(100% + 20px);
    margin-left: -10px;
    display: flex;
    flex-flow: wrap;
    .inp-wrapper {
      flex-basis: calc(25% - 20px);
      min-width: 260px;
      margin: 0 10px 10px;
      .label-form {
        display: inline-block;
        width: 100%;
        font-size: 13px;
        line-height: 20px;
        margin-bottom: 5px;
        color: #646c9a;
      }
    }
  }
  &__header {
    display: flex;
    &__aside {
      display: flex;
      align-items: center;
      flex-basis: 240px;
      .form-group {
        margin: 0 10px 0 0;
      }
    }
  }
  &__chart {
    padding: 20px;
    // max-height: 500px;
  }
}