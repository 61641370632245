.qr-code-modal {
  background-color: #fff;
  padding: 0;
  .popup {
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
