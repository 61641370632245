.package-info-panel {
  display: flex;
  overflow: hidden;
  margin: 0 -10px 20px;
  border-radius: 5px;
  &__warnings {
    padding: 20px;
    flex: 2;
    margin: 10px;
    border-radius: 5px;
    background-color: rgba(255, 184, 34, 0.7);
    h3 {
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
      margin-bottom: 10px;
      color: #000;
    }
    p {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 5px;
      color: #000;
    }
    .images-group {
      display: flex;
      align-items: center;
      img {
        margin: 0 10px;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  &__dangers {
    padding: 20px;
    min-width: 300px;
    text-align: center;
    margin: 10px;
    border-radius: 5px;
    background-color: rgba(253, 57, 122, 0.7);
    p {
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
      margin-bottom: 20px;
      color: #000;
    }
    img {
      max-width: 100px;
    }
  }
  &__info {
    flex: 1;
    padding: 30px 25px;
    text-align: center;
    .title {
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
      color: #646c9a;
    }
  }
  &__create-label {
    flex: 1;
    padding: 30px 25px;
    text-align: center;
    background-color: #44b2f1;
    transition: 0.2s;
    &:hover {
      background-color: rgba(68, 178, 241, 0.8);
    }
    span {
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
      color: #fff;
    }
  }
  &__confirm {
    flex: 1;
    padding: 30px 25px;
    text-align: center;
    background-color: #0abb87;
    transition: 0.2s;
    &[disabled] {
      pointer-events: none;
      background-color: rgba(89, 93, 110, 0.5);
    }
    &:hover {
      background-color: rgba(10, 187, 135, 0.8);
    }
    span {
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
      color: #fff;
    }
  }
  &__labels {
    margin-bottom: 20px;
    max-width: 50%;
    li {
      overflow: hidden;
      margin-bottom: 10px;
      border-radius: 5px;
      display: flex;
      background-color: #fff;
      box-shadow: 0px 0px 13px rgba(82, 63, 105, 0.05);
      .box-name {
        display: flex;
        align-items: center;
        flex: 1;
        padding: 5px 10px;
        p {
          font-size: 13px;
          line-height: 20px;
          font-weight: 500;
          color: #646c9a;
        }
      }
      .box-packages {
        flex: 1;
        padding: 5px 10px;
        p {
          font-size: 11px;
          line-height: 14px;
          font-weight: 500;
          margin-bottom: 3px;
          color: #646c9a;
        }
        ul {
          li {
            position: relative;
            padding-left: 10px;
            margin-bottom: 6px;
            font-size: 9px;
            line-height: 12px;
            color: #646c9a;
            &::before {
              content: "";
              position: absolute;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background-color: #646c9a;
              left: 0;
              top: 8px;
            }
          }
        }
      }

      button {
        flex: 1;
        display: inline-block;
        padding: 10px;
        background-color: #5d78ff;
        transition: 0.2s;
        span {
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          color: #fff;
        }
      }
    }
  }
}
