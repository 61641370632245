.main-table {
  width: 100%;
  border-bottom: 1px solid #ebedf2;
  &.searching {
    opacity: 0.4;
  }
  &__loader {
    width: 100%;
    padding: 100px;
    text-align: center;
  }
  &__head {
    th {
      cursor: pointer;
      padding: 13px 0 13px 30px;
      text-align: left;
      vertical-align: middle;
      span {
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
      }
      .sort-icons-group {
        display: inline-flex;
        flex-direction: column;
        vertical-align: middle;
        margin-top: -2px;
        margin-left: 10px;
        svg {
          margin: 0.7px;
        }
      }
    }
  }
  &__body {
    tr {
      border-top: 1px solid #ebedf2;
      transition: 0.2s;
      &:hover {
        background-color: #f9f9f9;
      }
      td {
        padding: 19px 0 19px 30px;
        text-align: left;
        font-size: 13px;
        line-height: 21px;
        color: #595d6e;
      }
    }
  }
  &__empty {
    padding: 20px;
    p {
      font-size: 13px;
      line-height: 20px;
      color: #595d6e;
    }
  }
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 15px 30px 0;
    button {
      margin-left: 15px;
    }
  }
  &__filters {
    padding: 20px 30px;
    border-bottom: 1px solid #ebedf2;
    .filters-wrapper {
      display: flex;
    }
    .filters-grid {
      flex: 1;
      display: none;
      flex-flow: wrap;
      padding-bottom: 20px;
      &.open {
        display: flex;
      }
      &.with-histogram {
        .form-group {
          flex-basis: 33.333%;
          max-width: 33.333%;
          min-width: 150px;
        }
      }
    }
    .filters-chart {
      padding-top: 10px;
      display: flex;
      justify-content: space-between;
      .form-label {
        display: inline-block;
        width: 100%;
        font-size: 13px;
        line-height: 20px;
        margin-bottom: 5px;
        color: #646c9a;
      }
    }
    .filters-collapse {
      text-align: right;
      .btn-collapse {
        font-size: 12px;
        line-height: 20px;
        color: #a2a5b9;
        padding: 5px;
        svg {
          max-width: 20px;
          margin-left: 4px;
        }
      }
    }
    .form-group {
      padding-top: 10px;
      flex-basis: 25%;
      max-width: 25%;
      padding-right: 20px;
      min-width: 300px;
      margin-bottom: 0;
      .inp-group {
        input {
          padding: 6px 10px;
          border-radius: 4px;
          border: 1px solid #e2e5ec;
          width: 100%;
          outline: none;
          font-size: 13px;
          line-height: 20px;
        }
      }
    }
  }
}
