.grn-progress {
  position: sticky;
  top: 0;
  height: 15px;
  border-radius: 2px;
  background-color: #0abe8c;
  z-index: 2;
  max-width: 100%;
  transition: 1s;
}

.grn-header {
  display: flex;
  border-bottom: 1px solid #ebedf2;

  &.without_warehouse {
    .grn-header__box {
      &:nth-child(1) {
        flex: 2;
      }

      &:nth-child(2) {
        flex: 4;
      }
    }
  }

  &__box {
    &:nth-child(1) {
      flex: 2;
    }

    &:nth-child(2) {
      flex: 1.5;
    }

    &:nth-child(3) {
      flex: 5;
    }

    padding: 25px 20px;

    &:not(:last-child) {
      border-right: 1px solid #ebedf2;
    }

    .form-group {
      .inp-wrapper {
        display: flex;
        align-items: center;
        width: 100%;

        .label-form {
          white-space: nowrap;
          margin-bottom: 0;
          width: initial;
          padding-right: 20px;
        }

        .inp-group {
          width: 100%;
        }
      }
    }

    .info-boxes-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-around;

      .info-box {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;

        &__icon {
          flex-basis: 40px;
          max-width: 40px;

          svg {
            max-width: 28px;
          }
        }

        &__text {
          p {
            white-space: nowrap;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #57565b;

            &.subtitle {
              font-weight: 400;
              font-size: 11px;
              line-height: 12px;
              color: #b6b8bf;
            }
          }
        }
      }
    }
  }
}

.supply-table {
  padding: 0 20px 10px;
  border-bottom: 1px solid #e9e9e9;

  &.blocked {
    pointer-events: none;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      z-index: 2;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  table {
    width: 100%;

    thead {
      border-bottom: 1px solid #ebedf2;

      th {
        padding: 16px 10px 13px;
        text-align: left;

        span {
          display: block;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: #c5c4cd;

          &.subinfo {
            font-weight: 400;
            font-size: 11px;
            line-height: 16px;
            max-width: 140px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .sort-button {
          padding: 0;
          margin: 0;
          display: inline-flex;
          align-items: center;

          span {
            margin-right: 5px;
          }

          &.asc {
            svg {
              transform: scaleY(-1);
            }
          }

          &.active {
            span {
              color: #5d78ff;
            }

            [fill] {
              fill: #5d78ff;
            }
          }
        }
      }
    }

    tbody {
      tr {
        vertical-align: middle;

        &:not(:last-child) {
          border-bottom: 1px solid #e9e9e9;
        }

        td {
          vertical-align: middle;
          padding: 6.5px 10px;
          font-weight: 400;
          font-size: 13px;
          line-height: 18px;

          &:last-child {
            padding: 0;
          }
        }

        &.is_decomposed {
          background-color: rgba(255, 182, 193, 0.9);
          .text-info,
          .text-muted {
            color: #000;
          }
        }

        &.is_warned {
          background-color: rgba(255, 184, 34, 0.2);
        }

        &.correct {
          background-color: #e6f8f3;
        }

        &.under {
          background-color: #feeded;
        }

        &.over {
          background-color: rgba($color: #6700a2, $alpha: 0.1);
        }

        &.no_location {
          background-color: #f5d9d9;
        }

        .table__label {
          display: inline-block;
          margin: 5px 0;
        }

        .text-info {
          font-size: 11px;
          line-height: 18px;
          color: #95949c;
        }

        .warehouse-form {
          display: flex;
          justify-content: space-between;
          align-items: center;
          &__box {
            flex: 1;
          }
        }

        .bl {
          border-left: 1px solid #e9e9e9;
        }

        .text-center {
          text-align: center;
        }

        .text-link {
          transition: 0.2s;

          &:hover {
            color: #5d78ff;
          }
        }

        .codes-group {
          display: flex;
          align-items: center;

          &__box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            & > svg,
            & > button {
              margin: 3px;
            }
          }
        }

        .save-group {
          margin: 0;

          .inp-wrapper {
            display: flex;
          }

          .btn-save {
            position: relative;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
            margin-left: -5px;
            background-color: #0abe8c;
            box-shadow: 0px 3px 16px #0abe8c26;
            transition: 0.2s;

            svg {
              max-width: 20px;
            }

            &[disabled] {
              opacity: 0;
              pointer-events: none;
            }

            &:hover {
              background-color: rgba($color: #0abe8c, $alpha: 0.6);
            }

            &:active {
              transform: scale(0.95);
            }
          }

          .inp-group {
            position: relative;

            input {
              max-width: 90px;
              min-width: 75px;
              padding-right: 30px;
              background-color: #fafafa;
              border-color: #d9d9d9;

              &[disabled] {
                opacity: 0.5;
              }
            }

            &::before {
              content: "";
              background-position: center;
              background-size: contain;
              position: absolute;
              top: 50%;
              right: 15px;
              transform: translateY(-50%);
            }

            &.correct {
              &::before {
                width: 11px;
                height: 9px;
                background-image: url("../../images/icons/inp-success.svg");
              }

              input {
                border-color: #0abb87;
              }
            }

            &.under {
              &::before {
                width: 9px;
                height: 9px;
                background-image: url("../../images/icons/inp-error.svg");
              }

              input {
                border-color: #fd397a;
              }
            }

            &.over {
              &::before {
                width: 9px;
                height: 9px;
                background-image: url("../../images/icons/inp-info.svg");
                background-image: url("../../images/icons/inp-info-purple.svg");
              }

              input {
                border-color: #6700a2;
              }
            }
          }
        }

        .box-count {
          text-align: center;

          strong {
            font-weight: 700;
            font-size: 15px;
            line-height: 18px;
          }
        }

        .suggested-count {
          display: inline-block;
          margin-top: 6px;
          font-weight: 400;
          font-size: 11px;
          line-height: 18px;
          padding: 0 12px;
          border-radius: 4px;
          color: #57565b;
          background: #f7f8fa;
          border: 1px solid #d9d9d9;

          &.pointer {
            cursor: pointer;
          }

          &.warning {
            color: #ffb822;
            border-color: #ffb822;
            font-weight: 500;
            background-color: rgba($color: #ffb822, $alpha: 0.15);
          }
        }
      }
    }
  }

  .number {
    width: 40px;
  }

  .image-box {
    width: 60px;
    font-size: 0;
    line-height: 0;

    .modal__trigger {
      width: 50px;
      height: 50px;
      border-radius: 4px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      overflow: hidden;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($color: #5d78ff, $alpha: 0.8);
        opacity: 0;
        transition: 0.2s;
        z-index: 1;
      }

      &::after {
        content: url("../../images/icons/zoom.svg");
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        opacity: 0;
        transition: 0.2s;
        transform: translate(-50%, -50%);
      }

      &:hover {
        &::before,
        &::after {
          opacity: 1;
        }
      }
    }
  }
}

.input-disabled {
  background-color: #f7f8fa;
  font-size: 13px;
  font-family: "Poppins";
}

.grn-summary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  background-color: #ffffff;

  &__left {
    flex: 3;

    .box-text {
      padding: 10px;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #57565b;

      svg {
        margin-right: 10px;
      }
    }
  }

  &__right {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__form {
    padding: 10px;
    max-width: 850px;

    .check-group {
      margin-top: 0;
    }

    .textarea-wrapper {
      margin-top: -10px;
      padding-left: 25px;
    }

    &--only-textarea {
      .textarea-wrapper {
        padding-left: 0;
        margin-top: 0;
      }
    }
  }

  .diff-list {
    &__item {
      display: flex;
      align-items: center;
      padding: 10px;
      border-bottom: 1px solid #ebedf2;

      .item-label {
        flex-basis: 140px;
      }

      .item-text {
        p {
          font-size: 13px;
          line-height: 20px;
          color: #57565b;
        }
      }
    }
  }

  .btn-revert {
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    color: #595d6e;
    padding: 5px 15px;
    margin-right: 20px;
  }

  .grn-summary-status {
    display: flex;
    align-items: center;

    svg {
      margin-right: 15px;
    }

    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      color: #57565b;
    }

    &.warning {
      svg {
        [fill="#5D78FF"] {
          fill: #f9d101;
        }

        [stroke="#E6F8F3"] {
          stroke: #f9d101;
        }
      }

      span {
        color: #f9d101;
      }
    }

    &.error {
      svg {
        [fill="#5D78FF"] {
          fill: #fd397a;
        }

        [stroke="#E6F8F3"] {
          stroke: #fd397a;
        }
      }

      span {
        color: #fd397a;
      }
    }

    &.success {
      svg {
        [fill="#5D78FF"] {
          fill: #0abb87;
        }

        [stroke="#E6F8F3"] {
          stroke: #0abb87;
        }
      }

      span {
        color: #0abb87;
      }
    }
  }
}

.logs-list {
  &__item {
    padding: 15px 30px;

    &:not(:last-child) {
      border-bottom: 1px solid #ebedf2;
    }

    p {
      font-size: 13px;
      line-height: 20px;
      font-weight: 300;
      color: #595d6e;

      &.title {
        font-weight: 400;
        color: #646c9a;
      }
    }
  }
}
