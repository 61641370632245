.calendar-group {
  & > div {
    width: 100%;
  }
  .react-date-picker,
  .react-datetime-picker {
    &__wrapper {
      width: 100%;
      padding: 0px;
      border-radius: 4px;
      border: none;
      border: 1px solid #e2e5ec;
      width: 100%;
      outline: none;
      transition: 0.2s;
    }
    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      background: #f3f6f9;
      border-left: 1px solid #e2e5ec;
    }
    &__inputGroup {
      font-size: 13px;
      line-height: 20px;
      padding: 4px 10px;
    }
  }
}
