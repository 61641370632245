.packs {
  &-view {
    background-color: #f2f3f8;
    padding: 70px 0;
    min-height: 100vh;
    &.lg {
      padding-top: 120px;
    }
    &.is_blocked {
      pointer-events: none;
      .blocked-loader {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 20px;
        z-index: 120000;
        background-color: rgba(0, 0, 0, 0.2);
        text-align: right;
        svg {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
  &-container {
    max-width: 1150px;
    margin: 0 auto;
    padding: 0 30px;
    &.lg {
      max-width: 1380px;
    }
  }
  &-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: #fff;
    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.05);
    &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      max-width: 1350px;
      margin: 0 auto;
    }
    .header-info {
      display: flex;
      align-items: center;
      .btn-back {
        svg {
          transform: scaleX(-1);
          margin-right: 10px;
        }
      }
      .title-group {
        padding-left: 30px;
        display: flex;
        align-items: center;
        .title {
          margin-left: 10px;
          font-size: 15px;
          font-weight: 500;
          color: #595d6e;
        }
      }
    }
    .account-group {
      display: flex;
      align-items: center;
      position: relative;
      .btn-qr {
        margin-right: 10px;
      }
      &__trigger {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 10px;
        svg {
          margin-right: 10px;
        }
        transition: 0.2s;
        &:hover {
          opacity: 0.8;
          span {
            text-decoration: underline;
          }
        }
      }
      &__panel {
        position: absolute;
        top: 55px;
        right: -10px;
        padding: 20px;
        min-width: 200px;
        border-radius: 5px;
        background: #fff;
        box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.05);
        li {
          white-space: nowrap;
          padding: 5px 0;
          button {
            &:hover {
              opacity: 0.8;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  &-empty {
    margin: 20px auto;
    padding: 25px;
    max-width: 650px;
    box-shadow: 0px 0px 13px rgba(82, 63, 105, 0.05);
    border-radius: 4px;
    background-color: #fff;
    &__header {
      margin-bottom: 15px;
      .title {
        margin-left: 5px;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #646c9a;
      }
    }
    p {
      font-size: 13px;
      line-height: 20px;
      font-weight: 300;
      margin-bottom: 15px;
      color: #595d6e;
    }
  }
  &-list {
    padding-top: 20px;
    span {
      font-size: 13px;
      line-height: 20px;
      font-weight: 300;
      color: #595d6e;
    }
    .list-item {
      display: flex;
      align-items: center;
      padding: 20px 30px;
      margin-bottom: 20px;
      border-radius: 4px;
      background-color: #fff;
      box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.05);
      position: relative;
      &.is_disabled {
        opacity: 0.5;
        pointer-events: none;
      }
      &.separator {
        &.important {
          background-color: #fd397a;
        }
        &.ready {
          background-color: #0abb87;
        }
        &.paused {
          background-color: #ffb822;
        }
        &.others {
          background-color: #000;
        }
        justify-content: center;
        p {
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.4px;
          text-transform: uppercase;
          font-weight: 500;
          color: #fff;
        }
      }
      &:not(.separator) {
        cursor: pointer;
        transition: 0.2s;
        &:hover {
          transform: scale(1.02);
        }
      }
      &__full {
        flex: 1;
      }
      &__identifier {
        flex-basis: 160px;
        max-width: 160px;
        display: flex;
        align-items: center;
        span {
          margin-left: 5px;
        }
      }
      &__date {
        flex-basis: 160px;
        max-width: 160px;
      }
      &__cart {
        flex-basis: 170px;
        max-width: 170px;
        span,
        strong {
          color: #fd397a;
        }
      }
      &__status {
        flex-basis: 160px;
        max-width: 160px;
      }
      &__arrow {
        flex-basis: 40px;
        max-width: 40px;
        display: flex;
        justify-content: center;
      }
      &__action-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        right: 0;
        width: 40px;
        height: 40px;
        transform: translate(50%, -50%);
        border-radius: 50%;
        svg {
          max-width: 100%;
          max-height: 100%;
        }
      }
      &.ready {
        .list-item__action-icon {
          padding: 5px;
          background-color: #0abb87;
          svg {
            margin-left: 2px;
            max-width: 10px;
          }
        }
      }
    }
  }
  &-suggested-cart {
    display: flex;
    align-items: center;
    margin: 40px auto 0;
    max-width: 650px;
    box-shadow: 0px 0px 13px rgba(82, 63, 105, 0.05);
    border-radius: 4px;
    background-color: #fff;
    &.is_fetching {
      opacity: 0.7;
    }
    &__header {
      flex: 1;
      text-align: center;
      padding: 20px 15px;
      .title {
        font-size: 20px;
        line-height: 30px;
        font-weight: 500;
        color: #646c9a;
      }
    }
    &__button {
      flex: 1;
      padding: 25px 15px;
      text-align: center;
      background-color: #0abb87;
      span {
        font-size: 20px;
        line-height: 30px;
        color: #fff;
      }
      &[disabled] {
        pointer-events: none;
        opacity: 0.7;
      }
    }
    &__refresh {
      max-width: 650px;
      margin: 10px auto;
      display: flex;
      justify-content: flex-end;
      .name {
        font-size: 60px;
        line-height: 70px;
        font-weight: 500;
        color: #646c9a;
      }
      .box_size {
        font-size: 20px;
        line-height: 30px;
        font-weight: 500;
        color: #595d6e;
      }
    }
  }
}
