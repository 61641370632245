.warehouse-tabs {
  background-color: #ebecf3;
  &.white {
    background-color: #fff;
    .tab {
      cursor: default;
    }
  }
  border-radius: 4px;
  overflow: hidden;
  .tab {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding: 15px 25px;
    transition: 0.2s;
    .icon {
      flex-basis: 40px;
      max-width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        max-width: 100%;
      }
    }
    .text {
      flex-basis: calc(100% - 40px);
      max-width: calc(100% - 40px);
      padding-left: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        font-size: 13px;
        line-height: 18px;
        font-weight: 300;
      }
    }
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 2px;
      background-color: #5d78ff;
      opacity: 0;
      transition: 0.2s;
    }
    &::before {
      content: "";
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 20px;
      right: 20px;
      background-color: rgba(162, 165, 185, 0.14);
    }
    &.tab-header {
      cursor: default;
      h2 {
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #48465b;
      }
    }
    &.sub-header {
      cursor: default;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        color: #595d6e;
      }
    }
    &.tab-footer {
      padding: 10px 30px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    &.active {
      background-color: #fff;
      &::after {
        opacity: 1;
      }
    }
  }
  &.rooms {
    .tab-header {
      .text {
        justify-content: space-between;
      }
    }
    .text,
    .action {
      flex: 1;
      display: flex;
      align-items: center;
    }
    .text {
      justify-content: flex-start;
      .identifier {
        margin-right: 15px;
        color: #a2a5b9;
      }
    }
    .action {
      justify-content: flex-end;

      .edit-buttons {
        margin-left: 15px;
      }
    }
  }
}
.order-button {
  width: 20px;
  height: 15px;
  border: 1px solid #e2e5ec;
  background-color: #fff;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 2px;
  transition: 0.2s;
  svg {
    max-width: 10px;
    max-height: 10px;
  }
  &.up {
    svg {
      transform: scaleY(-1);
    }
  }
  &[disabled]{
    opacity: 0.5;
  }
}


