.modal-table {
  width: 100%;
  tr {
    border-bottom: 1px solid #ebedf2;
    td {
      &:first-child {
        width: 200px;
      }
      padding: 10px;
      font-weight: 400;
      color: #48465b;
    }
  }
}
