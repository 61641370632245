.dashboard {
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 13px #523f690c;
  border-radius: 5px;
  margin: 10px 0;

  &__tabs {
    display: flex;
    background-color: #f2f3f8;

    .tab {
      flex: 1;
      background-color: #ebecf3;
      text-align: center;
      cursor: pointer;
      position: relative;

      &:last-child {
        &::before {
          display: none;
        }
      }

      a,span {
        display: block;
        padding: 9px 0;
      }

      &:hover {
        background-color: #e5e6ef;
      }

      &.active {
        background-color: white;
        position: relative;

        &:hover {
          background-color: white;
        }

        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: #5d78ff;
          height: 2px;
        }

        &:before {
          display: none;
        }
      }
    }
  }

  &--small {
    flex-basis: 49.2%;
  }

  &__button-section {
    padding: 20px 30px;
  }

  &__list {
    &-item {
      display: flex;
      align-items: center;
      padding: 20px 30px;
      position: relative;

      a {
        color: #5d78ff;

        &:hover {
          text-decoration: underline;
        }
      }

      .country {
        margin-right: auto;

        &-label--hidden {
          visibility: hidden;
        }
      }

      .no-margin {
        margin: 0;
      }

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $separator;
      }
    }
  }

  &__form {
    padding: 30px;

    &__submit {
      padding: 20px 30px;
    }

    &__footer {
      border-top: 1px solid $separator;
      display: flex;
      justify-content: flex-end;
      padding: 10px 30px;

      &.md {
        justify-content: flex-start;

        .box {
          width: 100%;
          max-width: 600px;
          display: flex;
          justify-content: flex-end;
        }
      }
    }

    &--pr-10 {
      //padding-left: 10px;
      padding-right: 10px;
    }
  }

  &__channel-label {
    padding: 30px;
    padding-top: 0;

    .channel-label-text {
      margin-bottom: 11px;
    }
  }

  &__header {
    display: flex;
    padding: 20px 30px 20px;
    align-items: center;
    border-bottom: 1px solid $separator;

    &--edit {
      padding: 18px 30px;
    }
  }

  &__title {
    margin-right: auto;
    margin-left: 10px;
  }

  &__searchfield {
    padding: 18px 30px;
    display: flex;

    .search-box {
      flex: 1;
      padding: 10px;
      border: 1px solid #e2e5ec;
      border-radius: 4px;
    }
  }

  &__modal-list {
    margin-bottom: 10px;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-flow: wrap;
      padding: 10px 0;
      border-bottom: 1px solid #ebedf2;

      span {
        font-size: 13px;
        line-height: 20px;
        font-weight: 300;
        color: #595d6e;
      }

      &.title {
        span {
          font-weight: 600;
        }
      }
    }
  }

  &__modal-table {
    margin: 20px 0;
    overflow-x: scroll;

    .title {
      font-size: 13px;
      line-height: 20px;
      font-weight: 600;
      margin-bottom: 10px;
      color: #595d6e;
    }

    & > table {
      min-width: 1000px;
    }

    table {
      width: 100%;

      .sub-head {
        & > th {
          padding: 0;
          vertical-align: middle;
        }

        &__table {
          min-height: 80px;

          &.with-padding {
            & > tr {
              & > th {
                padding-top: 10px;

                &:last-child {
                  padding-top: 0;
                }
              }
            }
          }

          th {
            padding: 0;
            vertical-align: middle;
          }

          &__table {
            min-height: 40px;
            border-top: 2px solid #ebedf2;
            margin-top: 10px;

            tr {
              th {
                padding: 5px;
              }
            }
          }
        }
      }

      .table-row {
        & > td {
          padding: 0;

          &:first-child {
            padding: 10px 2px;
            text-align: left;
          }

          & > table {
            min-height: 70px;

            tr {
              td {
                padding: 0;

                table {
                  min-height: 70px;
                }
              }
            }
          }
        }
      }

      tr {
        &:not(:last-child) {
          border-bottom: 2px solid #ebedf2;
        }

        td,
        th {
          font-size: 13px;
          line-height: 20px;
          padding: 10px 2px;
          color: #595d6e;

          &:not(:last-child) {
            border-right: 2px solid #ebedf2;
          }
        }

        td {
          vertical-align: middle;
          font-weight: 300;
          text-align: center;
          padding: 10px 0;
        }

        th {
          font-weight: 500;
          text-align: center;
        }

        .packer-statistics-bar {
          &__progress {
            width: 200px;

            .progress-separator {
              width: 1px;

              &::after {
                font-size: 8px;
                line-height: 10px;
              }
            }

            .progress-inner {
              height: 9px;
              max-width: 100%;
            }
          }
        }

        &.danger {
          background-color: rgb(252 142 142);
        }
      }
    }

    &.detailed {
      table {
        height: 100%;

        tr {
          th {
            font-size: 13px;
            line-height: 20px;
            padding: 10px 0 0 0;
            color: #595d6e;

            &:first-child {
              text-align: left;
            }

            &:not(:last-child) {
              border-right: 2px solid #ebedf2;
            }

            table {
              margin-top: 5px;
              width: 100%;
              border-top: 1px solid #ebedf2;

              th {
                &:not(:last-child) {
                  border-right: 1px solid #ebedf2;
                }
              }

              td,
              th {
                width: 50%;
                padding: 5px 1px;

                &:first-child {
                  text-align: center;
                  padding: 10px 0;
                }
              }
            }
          }

          td {
            padding: 0;

            &:first-child {
              padding: 5px 0;
            }

            &:not(:last-child) {
              border-right: 2px solid #ebedf2;
            }

            table {
              width: 100%;

              td,
              th {
                width: 50%;
                padding: 5px 1px;

                &:not(:last-child) {
                  border-right: 1px solid #ebedf2;
                }

                &:first-child {
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }

  &__modal-gdn-list {
    padding: 0 30px 20px;

    .gdn-item {
      display: flex;
      align-items: center;
      padding-right: 30px;

      &:not(:last-child) {
        border-bottom: 1px solid #ebedf2;
      }

      .product-image {
        flex-basis: 100px;
        max-width: 100px;
        height: 100px;
        background-position: center;
        background-size: cover;
      }

      .product-info {
        flex: 1;
        padding: 10px 30px;

        .product-name {
          font-size: 13px;
          line-height: 20px;
          font-weight: 300;
          color: #595d6e;
        }

        .product-sku {
          font-size: 13px;
          line-height: 20px;
          font-weight: 500;
          color: #595d6e;
        }
      }

      .product-quantity {
        min-width: 35px;
        padding: 0 5px;
        height: 35px;
        border: 1px solid #ebedf2;
        border-radius: 4px;
        text-align: center;

        span {
          font-size: 22px;
          line-height: 35px;
          font-weight: 500;
          color: #595d6e;
        }
      }
    }
  }

  &__info {
    padding: 20px;

    .box {
      &:not(:last-child) {
        margin-bottom: 20px;
      }

      .row {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
      }

      p,
      button:not(.btn) {
        font-size: 13px;
        line-height: 20px;
        font-weight: 300;
        color: #595d6e;

        &.title {
          font-weight: 400;
          color: #646c9a;
          margin-bottom: 7px;
        }
      }

      a {
        color: #5d78ff;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &.warning {
    background-color: #f9e6a8;

    .dashboard__header {
      border-bottom-color: #fff;
    }
    .table__header {
      tr {
        border-bottom-color: #fff;
      }
    }
    .table__body {
      tr:hover {
        background-color: #fcdf7f;
      }
    }
  }
}

.main-dashboard {
  min-height: calc(100vh - 40px);
  background-color: $dashboard-background;
}

.selectbox {
  border: none;
  cursor: pointer;
  border-radius: 4px;
  background: #f0f3ff 0% 0% no-repeat padding-box;
  padding: 4px 10px;
  padding-left: 4px;
  text-align: left;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 500;
  color: #93a2dd;
}

.icon__container {
  font-size: 0;
  line-height: 0;

  path {
    fill: $svg-icon;
  }

  circle {
    fill: $svg-icon;
  }

  .svg-rect {
    fill: $svg-icon;
  }
}

.dashboard-wrapper-flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.column-1 {
  flex-basis: calc(33.3333% - 5px);
  max-width: calc(33.3333% - 5px);

  &--container {
    display: flex;
    flex-direction: column;
  }
}

.column-2 {
  flex-basis: calc(66.666% - 5px);
  max-width: calc(66.666% - 5px);
}

.column-3 {
  flex-basis: 100%;
}

.column-half {
  flex-basis: calc(50% - 5px);
  max-width: calc(50% - 5px);
}
