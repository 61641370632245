.tooltip {
  position: relative;
  .tooltip-trigger {
    padding: 10px;
    &:hover + .tooltip-wrapper {
      display: block;
    }
  }
  .tooltip-wrapper {
    display: none;
    position: absolute;
    padding: 5px 0px;
    margin-bottom: 3px;
    right: -5px;
    top: 100%;
    z-index: 100;
  }
  .tooltip-arrow {
    position: absolute;
    width: 0px;
    height: 0px;
    border-color: transparent transparent rgb(255, 255, 255);
    border-style: solid;
    margin-left: -5px;
    border-width: 0px 5px 5px;
    top: 0px;
    right: 25px;
  }
  .tooltip-inner {
    width: 200px;
    padding: 10px 14px;
    color: rgb(102, 102, 102);
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(39, 43, 55, 0.15) 0px 0px 30px;
  }
  .box-localization {
    &:not(:first-child) {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid #e2e5ec;
    }
    p {
      font-size: 13px;
      line-height: 20px;
      padding-left: 25px;
      position: relative;
      margin-bottom: 8px;
      color: #48465b;
      font-weight: 300;
      span {
        display: inline-block;
        min-width: 60px;
      }
      strong {
        font-weight: 400;
      }
    }
    .icon {
      position: absolute;
      left: 0;
    }
  }
}
