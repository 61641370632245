.info_group {
  margin-bottom: 20px;
  .label,
  .value {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0px;
    padding: 0;
  }
  .label {
    color: #646c9a;
  }
  .value {
    color: #595d6e;
  }
}

.product-localizations {
  padding: 0 30px;
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    .popup {
      max-width: 450px;
      .tooltip {
        max-width: 20px;
        display: inline-block;
        .tooltip-trigger {
          padding: 0;
        }
      }
    }
    &:not(:last-child) {
      border-bottom: 1px solid #ebedf2;
    }
    .text {
      display: flex;
      align-items: center;
      flex: 1;
      .name {
        font-size: 13px;
        line-height: 20px;
        font-weight: 600;
        min-width: 160px;
        color: #595d6e;
      }
      .address {
        display: flex;
        align-items: center;
        flex-basis: 450px;
        max-width: 450px;
        li {
          font-weight: 300;
          font-size: 13px;
          line-height: 20px;
          padding: 0 7px;
          color: #595d6e;
        }
      }
      .stock {
        span {
          font-weight: 300;
          font-size: 13px;
          line-height: 20px;
          padding: 0 7px;
          color: #595d6e;
          span {
            padding: 0 3px;
            &.stock-positive {
              color: #0abb87;
            }
            &.stock-negative {
              color: #fd397a;
            }
          }
        }
      }
    }
  }
}
