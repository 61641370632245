@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600&display=swap&subset=latin-ext");

a {
  text-decoration: none;
  color: inherit;
  &.link {
    color: #5d78ff;
    transition: 0.2s;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
      text-decoration: underline;
    }
  }
}

strong {
  font-weight: 600;
}

.breadcrumb {
  color: #74788d;
  font-size: 10px;
}

.heading {
  font-size: 15px;
  color: #48465b;
  font-weight: 500;
  line-height: 20px;

  &__date,
  &__info {
    font-size: 13px;
    line-height: 20px;
    color: rgba($color: #595d6e, $alpha: 0.5);
    padding-left: 20px;
    span,
    strong {
      display: inline-block;
      padding: 0 6px;
      font-size: 13px;
      line-height: 20px;
      color: #595d6e;
    }
    strong {
      font-weight: 600;
    }
  }
}

.search-text {
  color: #4950577c;
  font-weight: 400;
}

.table-head {
  th {
    color: $table-head;
    font-weight: 500;
    text-align: left;
    padding: 15px;
  }
}

.table-body {
  td {
    color: $table-head;
    font-weight: 400;
    text-align: left;
  }
}

.pagination-info {
  margin-left: 14px;
  color: #646c9a;
  font-weight: 100;
}

.label-form {
  color: $label-form;
  display: inline-block;
}

.platform-name {
  color: $table-head;
  font-weight: 100;
  margin-right: 10px;
}

.country-label {
  color: #a2a5b9;
  font-weight: 100;
}

.check-group {
  .label-form {
    color: #646c9a;
  }
}

.text-muted {
  color: #bbbdc5;
}
.text-danger {
  color: #fd397a;
}

.text-primary {
  color: #5d78ff;
}

.text-bold {
  font-weight: 500;
}

.btn-info.btn-info {
  background-color: rgba(68, 178, 241, 1);
}

.text-overflow {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.btn-link {
  font-weight: 300;
  font-size: 13px;
  line-height: 20px;
  color: #5d78ff;
  &:hover {
    text-decoration: underline;
  }
}

.form-group.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.btn-green {
  span {
    color: #fff;
  }
}

.text-14 {
  font-size: 14px;
  line-height: 16px;
}

.text-11 {
  font-size: 11px;
  line-height: 14px;
}

.text-10 {
  font-size: 10px;
  line-height: 13px;
}

[data-tooltip] {
  position: relative;
  &::after {
    display: none;
    content: attr(data-tooltip);
    position: absolute;
    bottom: calc(100% + 3px);
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
    padding: 7px 5px;
    background-color: #57565b;
    min-width: 100px;
    font-size: 10px;
    line-height: 13px;
    color: #fff;
  }
  &::before {
    display: none;
    content: "";
    position: absolute;
    bottom: calc(100% - 7px);
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: #57565b transparent transparent transparent;
  }
  &:hover {
    &::after,
    &::before {
      display: block;
    }
  }
}

.info-holder {
  &__icon {
    svg {
      [fill="#494b74"] {
        fill: #5d78ff;
      }
    }
  }
}
.dotted-list {
  margin-left: 18px;
  list-style: disc;
}
.text-center {
  text-align: center;
}

.main-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  margin: 40px 0;
  color: #48465b;
  &.center {
    text-align: center;
  }
}

.text-success {
  color: #00c49f;
}
.text-danger {
  color: #f56565;
}

.inw-items {
  display: flex;
  flex-flow: wrap;
  gap: 10px;
  .inw-item {
    position: relative;

    input {
      border-radius: 4px;
      font-size: 13px;
      line-height: 20px;
      outline: none;
      padding: 4px 10px;
      transition: 0.2s;
      width: 100%;
      border: 1px solid #d9d9d9;
      text-align: center;
      &[disabled] {
        opacity: 0.5;
      }
    }

    &__info {
      display: none;
      background-color: #fff;
      padding: 10px;
      border-radius: 4px;
      position: absolute;
      z-index: 1;
      width: 200px;
      top: calc(100% + 1px);
      right: -1px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      svg {
        margin-right: 5px;
        [fill] {
          fill: #5d78ff;
        }
      }
      p {
        display: flex;
        align-items: center;
        .icon {
          text-align: center;
          display: inline-block;
          flex-basis: 30px;
        }
        .text {
          flex: 1;
          font-size: 12px;
          line-height: 16px;
        }

        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }
    &:hover {
      .inw-item__info {
        display: block;
      }
    }
    &.success {
      input {
        border-color: #0abb87;
      }
    }
    &.danger {
      input {
        border-color: #fd397a;
      }
    }
    &.warning {
      input {
        border-color: #ffe100;
      }
    }
  }
}
