.grn-line-batches {
  display: flex;
  flex-flow: wrap;
  padding: 10px;
  .grn-line-batch {
    margin: 2px;
    padding: 2px 4px;
    border-radius: 4px;
    border: 1px solid #95949c;
    line-height: 0;
    position: relative;
    &__remove{
      margin-left: 3px;
    }
    span {
      font-size: 10px;
      line-height: 14px;
      color: #595d6e;
    }
    &.deleted {
      border-color: #fd397a;
      & > span {
        color: #fd397a;
      }
    }
    &__info {
      display: none;
      background-color: #fff;
      padding: 10px;
      border-radius: 4px;
      position: absolute;
      z-index: 1;
      width: 200px;
      top: calc(100% + 1px);
      right: -1px;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
      svg {
        margin-right: 5px;
        [fill] {
          fill: #5d78ff;
        }
      }
      p {
        display: flex;
        align-items: center;
        .icon {
          text-align: center;
          display: inline-block;
          flex-basis: 30px;
        }
        .text {
          flex: 1;
          font-size: 12px;
          line-height: 16px;
        }
        &.danger {
          svg {
            [fill] {
              fill: #fd397a;
            }
          }
          span {
            color: #fd397a;
          }
        }
        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }
    &:hover {
      .grn-line-batch__info {
        display: block;
      }
    }
  }
}
