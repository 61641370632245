.release-details {
  padding: 30px;
  display: flex;
  align-items: flex-start;
  .box {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    .row {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
    p,
    button:not(.btn) {
      font-size: 13px;
      line-height: 20px;
      font-weight: 300;
      color: #595d6e;
      &.title {
        font-weight: 400;
        color: #646c9a;
        margin-bottom: 7px;
      }
    }
    a {
      color: #5d78ff;
      &:hover {
        text-decoration: underline;
      }
    }
    .packages-list{
      li{
        margin-bottom: 10px;
        padding-left: 15px;
        position: relative;
        &::before{
          content: '';
          position: absolute;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: #646c9a;
          left: 0;
          top: 7px;
        }
      }
    }
  }
}

.image-preview {
  padding: 30px;
  .btn-preview {
    width: 100%;
    height: 260px;
    border-radius: 4px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color: #5d78ff, $alpha: 0.8);
      opacity: 0;
      transition: 0.2s;
      z-index: 1;
    }
    &::after {
      content: url("../../images/icons/zoom-big.svg");
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      opacity: 0;
      transition: 0.2s;
      transform: translate(-50%, -50%);
    }
    &:hover {
      &::before,
      &::after {
        opacity: 1;
      }
    }
  }
}
