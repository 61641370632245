.dashboard-view {
  .info-icon {
    display: inline-block;
    text-align: center;
    svg {
      max-width: 20px;
    }
    [fill] {
      fill: #595d6e;
    }
  }
  .boxes-wrapper {
    display: flex;
    flex-flow: wrap;
    .summary-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      padding: 20px 30px;
      border-radius: 4px;
      box-shadow: 0px 0px 13px rgba(82, 63, 105, 0.05);
      background-color: #fff;
      margin: 10px;
      cursor: pointer;
      &.stats {
        cursor: default;
      }
      &__name {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: #48465b;
        svg {
          max-width: 25px;
          margin-right: 10px;
        }
        &.colored {
          color: #0abb87;
        }
      }
      &__value {
        font-size: 21px;
        line-height: 30px;
        font-weight: 500;
        color: #48465b;
      }
      &__values {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        flex: 1;
        .single-value {
          flex-basis: 80px;
          text-align: center;
          .info {
            font-size: 12px;
            line-height: 14px;
            color: #9d9fa3;
            &--bottom {
              font-size: 9px;
              line-height: 11px;
              color: #bbbdc5;
            }
          }
          .main-value {
            font-size: 16px;
            line-height: 26px;
            font-weight: 500;
            color: #48465b;
          }
        }
      }
    }
    .box {
      margin: 10px;
      flex-basis: calc(33.333% - 20px);
      max-width: calc(33.333% - 20px);
      &.full {
        flex: 1;
        max-width: initial;
      }
      .dashboard {
        margin: 0 0 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .chart-box {
      &__body {
        padding: 20px 30px;
      }
      &__selects {
        width: calc(100% + 20px);
        margin-left: -10px;
        display: flex;
        .form-group {
          flex: 1;
          margin: 0 10px;
        }
      }
      &__header {
        display: flex;
        &__aside {
          display: flex;
          align-items: center;
          flex-basis: 240px;
          .form-group {
            margin: 0 10px 0 0;
          }
        }
      }
    }
    .table {
      a {
        color: #5d78ff;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .history-box {
      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 30px;
        .title {
          font-size: 15px;
          line-height: 20px;
          font-weight: 500;
          color: #48465b;
        }
      }
      &__body {
        padding: 10px 0;
        border-top: 1px solid #ebedf2;
        li {
          cursor: pointer;
          position: relative;
          transition: 0.2s;
          padding: 10px 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 13px;
          line-height: 20px;
          font-weight: 300;
          color: #595d6e;
          time {
            font-family: monospace;
          }

          &:not(:last-child)::after {
            content: "";
            position: absolute;
            left: 30px;
            right: 30px;
            bottom: 0;
            height: 1px;
            background-color: #ebedf2;
          }
          &:hover {
            background-color: rgba(
              $color: #e2e5ec,
              $alpha: 0.28
            );
          }
        }
      }
      &__footer {
        padding: 10px 30px;
        border-top: 1px solid #ebedf2;
        .btn-link {
          font-weight: 300;
          font-size: 13px;
          line-height: 20px;
          color: #5d78ff;
        }
      }
    }
    .alerts {
      background-color: rgba(253, 57, 122, 0.8);
      .alerts-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 30px;
        border-bottom: 1px solid #fff;
        .title {
          font-size: 15px;
          line-height: 20px;
          font-weight: 500;
          color: #fff;
        }
      }
      .alerts-list {
        padding: 0 30px;
        li {
          position: relative;
          transition: 0.2s;
          padding: 10px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 13px;
          line-height: 20px;
          font-weight: 300;
          color: #fff;
          button {
            padding: 3px 8px;
            span {
              font-size: 12px;
            }
          }
          &:not(:last-child) {
            border-bottom: 1px solid #ebedf2;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1260px) {
    .boxes-wrapper {
      .summary-box {
        flex-basis: 100%;
        max-width: 100%;
        margin: 0 0 10px;
      }
      .box {
        flex-basis: 100%;
        max-width: 100%;
        margin: 0;
        .dashboard {
          margin: 0 0 10px;
          &:last-child {
            margin: 0 0 10px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 650px) {
    .boxes-wrapper {
      .summary-box {
        padding: 15px;
      }
      .chart-box {
        &__selects {
          flex-flow: wrap;
          .form-group {
            flex-basis: calc(50% - 20px);
            max-width: calc(50% - 20px);
            margin: 0 10px 10px;
          }
        }
        .chart {
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
