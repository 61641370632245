.delivery-methods-list {
  display: flex;
  flex-flow: wrap;
  padding: 20px;
  li {
    cursor: pointer;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-basis: 200px;
    max-width: 200px;
    margin: 10px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    transition: 0.2s;
    &.active {
      border-color: rgb(93, 120, 255);
    }
    &.disabled{
      opacity: 0.5;
      pointer-events: none;
    }
    p {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 5px;
      font-weight: 500;
    }
    img {
      max-width: 100px;
    }
  }
}
