$colors: (
  #6666FF,
  #9900B3,
  #33991A,
  #FFB399,
  #FF1A66,
  #E6B333,
  #33cbe6,
  #470202,
);

.delivery-modal {
  &__form {
    padding: 20px 30px;

    .delivery-label {
      font-size: 13px;
      line-height: 20px;
      margin-bottom: 5px;
      color: #646c9a;
    }

    .add-weight {
      font-size: 13px;
      line-height: 20px;
      border-radius: 3px;
      margin-left: 20px;
      padding: 4px 10px;
      color: #597dff;
      border: 1px solid #597dff;
      transition: 0.2s;

      &:hover {
        opacity: 0.8;
      }

      &:active {
        transform: scale(0.95);
      }
    }

    .weight-box {
      display: flex;
      align-items: center;
      margin-bottom: 13px;

      .index {
        min-width: 20px;
        padding-right: 5px;

        span {
          font-size: 13px;
          line-height: 20px;
        }
      }

      .form-group {
        margin: 0;
        max-width: 70px;
      }

      .btn {
        margin-left: 5px;
      }
    }

    .form-wrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-flow: wrap;

      .box {
        flex-basis: calc(50% - 10px);
        max-width: calc(50% - 10px);
        margin-bottom: 20px;

        &-full {
          flex-basis: 100%;
          max-width: 100%;
          margin-bottom: 20px;
        }
      }
    }

    .checks-group {
      &-wrapper {
        display: flex;
        align-items: flex-start;
        flex-flow: wrap;
        margin-left: -5px;
        margin-top: -5px;
        width: calc(100% + 10px);

        .form-group {
          flex-basis: calc(33.333% - 10px);
          max-width: calc(33.333% - 10px);
          margin: 5px;
        }
      }

      .form-group {
        border: 1px solid #e2e5ec;
        padding: 10px;
        border-radius: 4px;

        .check-group {
          margin: 0;

          label {
            margin: 0;
            font-size: 13px;
            line-height: 20px;
          }
        }
      }
    }

    .packages-list {
      li {
        display: flex;
        align-items: flex-end;
        margin: 0 -5px 20px;

        &:last-child {
          margin-bottom: 0;
        }

        .form-group {
          margin: 0 5px;
        }

        .add-weight {
          margin: 0 0 0 5px;
        }

        .remove-package {
          margin-left: 5px;
          padding: 2px;
          border-radius: 4px;
          border: 1px solid #e2e5ec;
          outline: none;
        }

        .package-number {
          flex-basis: 100px;
          max-width: 100px;
          font-size: 16px;
          line-height: 35px;
          color: #646c9a;
        }
      }
    }

    .btn-size-option {
      padding: 10px;
      min-width: 50px;
      text-align: center;
      justify-content: center;
      margin: 0 4px 4px;

      &-lg {
        width: calc(33.333% - 8px);
        padding: 20px 10px;

        span {
          font-size: 20px;
          line-height: 30px;
        }
      }

      &.active {
        border-color: #567dff;
        box-shadow: 0px 0px 16px rgba(90, 120, 255, 0.15);
      }
    }
  }
}

.package-boxes {
  padding: 0 20px;

  &__box {
    margin: 20px 0;

    h3 {
      font-size: 17px;
      line-height: 22px;
      margin-bottom: 5px;
      color: #646c9a;
    }

    .box-list {
      display: flex;
      align-items: center;
      flex-flow: wrap;
      margin: 0 -10px;

      &__image {
        width: 80px;
        text-align: center;

        img {
          max-width: 50px;
        }
      }

      &__item {
        cursor: pointer;
        border: 2px solid #e2e5ec;
        border-radius: 4px;
        min-height: 90px;
        width: 200px;
        padding: 20px;
        margin: 10px;
        display: flex;
        flex-flow: wrap;
        align-items: center;
        justify-content: center;
        position: relative;
        transition: 0.2s;

        span {
          font-size: 18px;
          line-height: 30px;
          font-weight: 600;
          color: #566374;
          transition: 0.2s;
        }

        .item-sizes {
          p {
            font-size: 12px;
            line-height: 16px;
          }
        }

        .btn.btn-icon {
          position: absolute;
          top: 0;
          right: 0;
        }

        &.disabled,
        &:hover {
          span {
            color: #fff;
          }
        }

        &.disabled {
          cursor: not-allowed;
          opacity: 0.4;
        }
      }
    }

    &:first-child {
      .box-list__item {
        &:hover {
          background-color: #48465b;
          border-color: #48465b;
        }
      }
    }

    @for $i from 0 to length($colors) {
      &:nth-child(#{$i + 2}) {
        .box-list__item {
          @for $j from 2 to 6 {
            &:nth-child(#{$j}) {
              border-color: rgba($color: nth($colors, $i + 1), $alpha: calc(($j + 3) / 10));

              &.disabled,
              &:hover {
                background-color: rgba($color: nth($colors, $i + 1), $alpha: 1);
              }
            }
          }
        }
      }
    }
  }

  &__form {
    .form-group {
      margin-bottom: 20px;

      &__header {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        h3 {
          font-size: 20px;
          line-height: 26px;
          font-weight: 600;
          color: #566374;
        }
      }

      .row {
        margin: 10px -10px 0;

        &>div {
          margin: 0 10px;
        }
      }
    }
  }
}