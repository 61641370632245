.packer-modal {
  .popup {
    max-width: 500px;
  }
  .popup__body {
    padding: 20px 30px;
    p {
      font-size: 13px;
      line-height: 20px;
      font-weight: 300;
      color: #595d6e;
    }
    .packs-list {
      padding: 0;
      margin: 20px 0;
      .item {
        font-size: 13px;
        line-height: 25px;
        font-weight: 300;
        color: #595d6e;
        svg {
          margin-bottom: 3px;
          margin-right: 5px;
        }
        &.danger {
          color: #fd397a;
        }
      }
    }
  }
  .popup__footer{
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
