.packer-statistics-bar {
  padding-bottom: 10px;
  overflow: hidden;
  &__label {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 2px;
  }
  &__progress {
    width: 300px;
    margin-bottom: 5px;
    border-radius: 2px;
    position: relative;
    border: 2px solid #ebedf2;
    .progress-separator {
      width: 2px;
      background-color: #ebedf2;
      position: absolute;
      top: 0;
      bottom: 0;
      &::after {
        content: attr(data-limit);
        font-size: 10px;
        line-height: 12px;
        color: #595d6e;
        position: absolute;
        top: calc(100% + 3px);
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
      }
    }
    .progress-inner {
      height: 12px;
      background-color: #0abb87;
    }
  }
}