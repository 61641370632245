.schema-shelf {
  display: flex;
  align-items: flex-start;
  &__info {
    flex-basis: 150px;
    max-width: 150px;
    padding: 10px;
    p {
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #57565b;
      &.info {
        margin-top: 9px;
        max-width: 105px;
        font-size: 10px;
        line-height: 12px;
        color: #c5c4cd;
      }
    }
  }
  &__rows {
    flex: 1;
    padding-left: 40px;
    .shelf-row {
      &--add {
        padding-left: 35px;
      }
      &--info{
        padding: 5px;
        padding-left: 36px;
        p{
          flex-basis: 155px;
        }
      }
      padding: 10px;
      border-bottom: 1px solid #ebedf2;
      position: relative;
      display: flex;
      align-items: center;
      &__position {
        position: absolute;
        left: -30px;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #c5c4cd;
      }
      &__icon {
        flex-basis: 25px;
        max-width: 25px;
      }
      &__form {
        display: flex;
        .form-group {
          margin: 0;
          padding: 0 5px;
        }
      }
      .btn-icon {
        margin-left: auto;
      }
    }
  }
}
