.speditors-prices {
  max-width: 1850px;
  overflow-x: scroll;
  table {
    min-width: 1850px;
    width: 100%;
    thead {
      border-bottom: 1px solid #ebedf2;
      th {
        text-align: center;
        padding: 5px 7px;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        white-space: nowrap;
        color: #c5c4cd;
        border-right: 1px solid #ebedf2;
        &.highlighted{
          background-color: #e9e9e9;
        }
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid #e9e9e9;
        td {
          white-space: nowrap;
          border-right: 1px solid #e9e9e9;
          &.highlighted{
            input{
              background-color: #e9e9e9;
            }
          }
          &:first-child {
            padding: 5px 10px;
            text-align: center;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            font-weight: 500;
          }
          input {
            text-align: center;
            width: 50px;
            height: 30px;
            font-weight: 400;
            font-size: 13px;
            border: none;
            line-height: 16px;
            color: #57565b;
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
