.expano-flag {
  display: inline-block;
  margin: 0 10px 5px 0;
  span {
    font-size: 8px;
    line-height: 20px;
    letter-spacing: 0px;
    border-radius: 2px;
    padding: 3px 5px;
    font-weight: 600;
    color: #595d6e;
    border: 1px solid #707070;
  }
}
