.packs-count {
  display: flex;
  align-items: center;
  padding-left: 25px;
  .count-item {
    display: flex;
    align-items: center;
    padding: 0 5px;
    .count {
      margin-left: 5px;
      font-size: 13px;
      line-height: 20px;
      font-weight: 500;
      color: #595d6e;
    }
  }
}
