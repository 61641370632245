.inventarization {
  max-width: 600px;
  background-color: #fff;
  margin: 10px 0;
  border-radius: 5px;
  overflow: hidden;
  table {
    width: 100%;
    thead {
      border-bottom: 1px solid #ebedf2;
      th {
        text-align: center;
        padding: 5px 7px;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        white-space: nowrap;
        color: #c5c4cd;
        border-right: 1px solid #ebedf2;
        &.highlighted {
          background-color: #e9e9e9;
        }
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid #e9e9e9;
        td {
          white-space: nowrap;
          border-right: 1px solid #e9e9e9;
          &.highlighted {
            input {
              background-color: #e9e9e9;
            }
          }
          &:nth-child(1),
          &:nth-child(2) {
            padding: 5px 10px;
            font-weight: 400;
            font-size: 13px;
            text-align: center;
            line-height: 16px;
            font-weight: 500;
          }
          &:last-child {
            text-align: center;
          }
          .save-group {
            margin: 0;
            padding: 5px;
            .inp-wrapper {
              display: flex;
            }
            .btn-save {
              position: relative;
              min-width: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 3px;
              margin-left: -5px;
              background-color: #0abe8c;
              transition: 0.2s;
              svg {
                max-width: 20px;
              }
              &[disabled] {
                background-color: #d9d9d9;
                pointer-events: none;
              }
              &:hover {
                background-color: rgba(
                  $color: #0abe8c,
                  $alpha: 0.6
                );
              }
              &:active {
                transform: scale(0.95);
              }
            }
            .inp-group {
              position: relative;
              input {
                text-align: center;
                border-color: #d9d9d9;
                &[disabled] {
                  opacity: 0.5;
                }
              }
              &.correct {
                input {
                  border-color: #0abb87;
                }
              }
              &.under {
                input {
                  border-color: #fd397a;
                }
              }
              &.over {
                input {
                  border-color: #6700a2;
                }
              }
            }
          }
        }
      }
    }
  }
}
