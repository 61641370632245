.confirm-panel {
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0px 0px 13px rgba(82, 63, 105, 0.05);
  &.is_completed {
    background-color: #0abb87;
    .confirm-panel__info .title {
      color: #fff;
    }
  }
  &__info {
    flex: 1;
    padding: 30px 25px;
    text-align: center;
    .title {
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
      color: #646c9a;
    }
  }
  &__create-label {
    flex: 1;
    padding: 30px 25px;
    text-align: center;
    background-color: #44b2f1;
    transition: 0.2s;
    &:hover {
      background-color: rgba(68, 178, 241, 0.8);
    }
    span {
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
      color: #fff;
      &.sm {
        font-size: 12px;
        line-height: 20px;
      }
    }
    &[disabled] {
      pointer-events: none;
      background-color: rgba(255, 0, 0, 0.9);
    }
  }
  &__confirm {
    flex: 1;
    padding: 30px 25px;
    text-align: center;
    background-color: #0abb87;
    transition: 0.2s;
    &[disabled] {
      pointer-events: none;
      background-color: rgba(89, 93, 110, 0.5);
    }
    &:hover {
      background-color: rgba(10, 187, 135, 0.8);
    }
    span {
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
      color: #fff;
    }
  }
  &__labels {
    margin-bottom: 20px;
    max-width: 50%;
    li {
      overflow: hidden;
      margin-bottom: 10px;
      border-radius: 5px;
      display: flex;
      background-color: #fff;
      box-shadow: 0px 0px 13px rgba(82, 63, 105, 0.05);
      .box-name {
        display: flex;
        align-items: center;
        flex: 1;
        padding: 5px 10px;
        p {
          font-size: 13px;
          line-height: 20px;
          font-weight: 500;
          color: #646c9a;
        }
      }
      .box-packages {
        flex: 1;
        padding: 5px 10px;
        p {
          font-size: 11px;
          line-height: 14px;
          font-weight: 500;
          margin-bottom: 3px;
          color: #646c9a;
        }
        ul {
          li {
            position: relative;
            padding-left: 10px;
            margin-bottom: 6px;
            font-size: 9px;
            line-height: 12px;
            color: #646c9a;
            &::before {
              content: "";
              position: absolute;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background-color: #646c9a;
              left: 0;
              top: 8px;
            }
          }
        }
      }

      button {
        flex: 1;
        display: inline-block;
        padding: 10px;
        background-color: #5d78ff;
        transition: 0.2s;
        span {
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          color: #fff;
        }
      }
    }
  }
}
