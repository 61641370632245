.packs {
  &__container {
    background-color: #f2f3f8;
    min-height: 100vh;
  }
  &__wrapper {
    padding: 100px 25px 25px;
    max-width: 1140px;
    margin: 0 auto;
  }
  &__list {
    .pack {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 30px;
      margin-bottom: 20px;
      background-color: #fff;
      box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      transition: 0.2s;
      &__number {
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        color: #595d6e;
        padding-right: 15px;
        min-width: 250px;
      }
      &__products {
        flex: 1;
        padding: 0 15px;
        .product {
          display: flex;
          align-items: center;
          padding: 5px 0;
          &:not(:last-child) {
            border-bottom: 1px solid #ebedf2;
          }
          .sku,
          .amount {
            text-align: left;
          }
          .sku {
            min-width: 125px;
          }
          .amount{
            min-width: 75px;
          }
          span {
            font-size: 13px;
            line-height: 20px;
            font-weight: 300;
            color: #595d6e;
          }
          .image {
            width: 30px;
            height: 30px;
            background-position: center;
            background-size: cover;
            border-radius: 4px;
            margin-right: 15px;
          }
        }
      }
      &__arrow {
        padding: 0 10px 0 40px;
      }
      &:hover {
        opacity: 0.7;
      }
      &:active {
        transform: scale(0.98);
      }
    }
  }
}
