.aisle-preview {
  .preview-label {
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 3px;
    color: #646c9a;
  }
  .preview-table {
    .loader-wrapper {
      display: none;
    }
    &.is-loading {
      position: relative;
      .loader-wrapper {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(226, 229, 236, 0.5);
      }
    }
    &__head {
      text-align: center;
      padding: 4px 10px;
      border-radius: 4px 4px 0px 0px;
      border: 1px solid #ced6fd;
      background-color: #edf0ff;
    }
    &__title {
      font-size: 13px;
      line-height: 20px;
      font-weight: 600;
      text-transform: uppercase;
      color: #646c9a;
    }
    &__body {
      border-radius: 0 0 4px 4px;
      border: 1px solid #e2e5ec;
      border-top: 0;
      max-height: 235px;
      min-height: 235px;
      overflow-y: scroll;
    }
    &__item {
      padding: 6px 12px;
      display: flex;
      align-items: flex-start;
      &:nth-child(odd) {
        background-color: #f9f9fb;
      }
      .item-position {
        flex-basis: 100px;
        max-width: 100px;
        font-size: 11px;
        line-height: 20px;
        font-weight: 500;
        color: #646c9a;
      }
      .item-product {
        flex: 1;
        padding-left: 10px;
        font-size: 11px;
        line-height: 16px;
        font-weight: 300;
        color: #48465b;
      }
    }
  }
}
