.button-group {
  position: relative;
  &__trigger {
    margin: 0 5px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-size: 13px;
    line-height: 20px;
    border-radius: 4px;
    padding: 4px 10px 4px 15px;
    border: 1px solid #d7d7da;
    color: #595d6e;
    transition: 0.2s;
    svg {
      margin-right: 5px;
    }
    .collapse-arrow {
      margin: 0 0 0 5px;
      transition: 0.2s;
    }
  }
  &__content {
    display: none;
    position: absolute;
    left: 5px;
    right: 5px;
    top: calc(100% - 1px);
    border: 1px solid #d7d7da;
    border-top: 0;
    border-radius: 0 0 4px 4px;
    li {
      cursor: pointer;
      padding: 8px 10px;
      background-color: #fff;
      text-align: center;
      font-size: 12px;
      color: #595d6e;
      transition: 0.2s;
      &:not(:last-child) {
        border-bottom: 1px solid #d7d7da;
      }
      &:hover {
        background-color: #d7d7da;
      }
    }
  }
  &.open {
    .button-group__trigger {
      border-radius: 4px 4px 0 0;
      .collapse-arrow {
        transform: scaleY(-1);
      }
    }
    .button-group__content {
      display: block;
    }
  }
}
