@import "~expano-components/styles/status-colors.scss";
@import "~expano-components/styles/form.scss";
@import "~expano-components/styles/input.scss";
@import "~expano-components/styles/textarea.scss";
@import "~expano-components/styles/check.scss";
@import "~expano-components/styles/buttons.scss";
@import "~expano-components/styles/labels.scss";
@import "~expano-components/styles/tree.scss";
@import "~expano-components/styles/popup.scss";
@import "~expano-components/styles/pagination.scss";
@import "~expano-components/styles/image-uploader.scss";
// @import "~expano-components/styles/breadcrumbs.scss";
@import "~expano-components/styles/sidebar.scss";
@import "~expano-components/styles/topbar.scss";
@import "~expano-components/styles/main.scss";
@import "~expano-components/styles/login.scss";
@import "~expano-components/styles/change-app-modal.scss";
@import "~expano-components/styles/tradesk_label.scss";
@import "~expano-components/styles/page-panel.scss";
@import "~expano-components/styles/status-label.scss";

.btn-note {
  svg {
    max-width: 20px;
    margin-right: 8px;
  }
}

.btn-pink {
  color: #fff;
}

.topbar-navigator {
  display: inline;
  color: #fff;
  text-decoration: underline;
  margin-right: 20px;
}

.popup.danger {
  background-color: #fd397a;
  color: #fff;

  .popup-close {
    [fill] {
      fill: #fff;
    }
  }
}

.popup.full {
  max-width: 100%;
}

.popup__header {
  .additional_icon {
    max-height: 25px;
    margin-left: 25px;
  }
}

.topbar {
  @media screen and (max-width: 700px) {
    .topbar__action {
      margin-left: 5px;
      flex: 1;
      justify-content: space-between;
    }

    .nav-user,
    .topbar-navigator {
      display: none;
    }
  }
}

.estimate-missings {
  max-width: 100%;

  .popup__body {
    padding: 0;

    .main-list__pagination {
      display: none;
    }
  }
}
.page-panel {
  z-index: 10;
}
