.status-panel {
  display: flex;
  align-items: center;
  padding: 35px 50px;
  border-radius: 4px;
  margin-bottom: 20px;

  &__info {
    display: flex;
    align-items: flex-start;
    flex: 1;
    .info-icon {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid rgba(255, 255, 255, 0.1);
      svg {
        max-width: 100%;
      }
    }
    .info-text {
      flex: 1;
      padding-left: 35px;
      .title {
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #fff;
      }
      .desc {
        font-size: 13px;
        line-height: 20px;
        margin-top: 15px;
        color: #fff;
      }
      .select-box {
        margin-top: 13px;
        max-width: 360px;
        .label-form {
          font-weight: 300;
          color: #fff;
        }
      }
      .packs-list {
        display: flex;
        align-items: flex-start;
        &__title {
          font-size: 13px;
          line-height: 24px;
          font-weight: 300;
          color: #fff;
        }
        .list {
          padding-left: 10px;
          li {
            margin-bottom: 10px;
            .list-number {
              font-size: 13px;
              line-height: 20px;
              font-weight: 300;
              color: #fff;
            }
            p {
              white-space: nowrap;
            }
          }
        }
      }
      .btn-download {
        padding: 2px 7px;
        display: inline-block;
        border-radius: 3px;
        margin-left: 10px;
        background-color: #5d78ff;
        transition: 0.2s;
        span {
          font-size: 13px;
          line-height: 20px;
          color: #fff;
        }
        &:hover {
          opacity: 0.8;
        }
        &:active {
          transform: scale(0.95);
        }
      }
      .btn-add-label {
        padding: 2px 7px;
        display: inline-block;
        border-radius: 3px;
        margin-left: auto;
        margin-right: 0;
        border: 1px solid #fff;
        background-color: transparent;
        transition: 0.2s;
        span {
          font-size: 13px;
          line-height: 20px;
          color: #fff;
        }
        &:hover {
          opacity: 0.8;
        }
        &:active {
          transform: scale(0.95);
        }
      }
    }
  }
  .btn-next {
    padding: 6px 15px;
    display: inline-flex;
    align-items: center;
    border-radius: 3px;
    margin-left: 5px;
    background-color: #fff;
    border: none;
    transition: 0.2s;
    box-shadow: 0px 3px 16px rgba(255, 255, 255, 0.15);
    span {
      font-size: 13px;
      line-height: 20px;
      white-space: nowrap;
    }
    svg {
      margin-left: 10px;
    }
    &:hover {
      opacity: 0.8;
    }
    &:active {
      transform: scale(0.95);
    }
  }
  &.confirm {
    box-shadow: 0px 10px 20px rgba(10, 187, 135, 0.3);
    background-color: #0abb87;
    .btn-next {
      span {
        color: #0abb87;
      }
    }
  }
  &.warning {
    box-shadow: 0px 10px 20px rgba(255, 184, 34, 0.3);
    background-color: #ffb822;
    .btn-next {
      span {
        color: #ffb822;
      }
      svg {
        [stroke] {
          stroke: #ffb822;
        }
      }
    }
  }
  &.error {
    box-shadow: 0px 10px 20px rgba(253, 57, 122, 0.3);
    background-color: rgb(253, 57, 122);
    .btn-next {
      span {
        color: #fd397a;
      }
      svg {
        [stroke] {
          stroke: #fd397a;
        }
      }
    }
  }
  &.soft_error {
    box-shadow: 0px 10px 20px rgba(243, 134, 171, 0.3);
    background-color: rgb(243, 134, 171);
    .btn-next {
      span {
        color: #f385aa;
      }
      svg {
        [stroke] {
          stroke: #f385aa;
        }
      }
    }
  }
  &.cancel {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
    background-color: rgb(0, 0, 0);
    .btn-next {
      span {
        color: #000;
      }
      svg {
        [stroke] {
          stroke: #000;
        }
      }
    }
  }
}
